body {
  margin: 0;
  font-size: 16px;
}

#root {
  height: 100%;
}

#root > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: stretch;  
  align-content: normal;
  height: 100%;
}

#root > div > nav {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis:250px;
  min-width: 250px;
  align-self: auto;
  order: 0;
  position: fixed;
  height: 100%;
  z-index: 2;
}

#root > div > div {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis:250px;
  min-width: 250px;
  align-self: auto;
  order: 0;
  position: relative;
}

#root > div > main {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

nav {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #efefef;
  background-image: url('./media/deuso-logo-k.svg');
  background-repeat: no-repeat;
  background-position: center bottom 30px;
  background-size: 140px 60px;
  padding-top: 20px;
  min-height: 400px;
}

nav > a.imprint {
  display: block;
  position:absolute;
  bottom: 10px;
  font-size: 10px;
  text-align: center;
  width: 100%;
  left: -10px;
}

nav a {
  display: block;
  font-size: .85rem;
  padding: 10px 5px;
  border-radius: 6px;
  margin: 5px auto;
  width: 220px;
  color: rgb(55, 55, 55);
  text-decoration: none;
  text-align: left;
  text-indent: 20px;
}
nav a.active {
  border-radius: 6px;
  background-color: rgb(99, 99, 99);
  color: white;
}
li {
  font-family: Calibri, "Helvetica", Arial, sans-serif;
}

main fieldset {
  margin: 20px;
  min-height: 250px;
  font-family: Calibri, "Helvetica", Arial, sans-serif;
}
.pres-text {
  padding: 10px 40px;
  max-width: 950px;
}

.newcol {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 350px 1fr;
}

.newcol.thinner {
  column-gap: 5px;
  grid-template-columns: 250px 1fr;
}

.pres-text h1,
.pres-text h2,
.pres-text h3
 {
  font-family: Calibri, "Helvetica", Arial, sans-serif;
  font-weight: lighter;
  color: rgb(99, 99, 99);
}

.pres-text h1 {
  font-size: 2.2em;
}

.pres-text h2 {
  font-size: 1.85em;  
}

.pres-text h3 {
  font-size: 1.5em;  
}

.pres-text p, 
.pres-text li {
  font-family: Calibri, "Helvetica", sans-serif;
  font-weight: lighter;
  line-height: 1.5;
  font-size: 1em;
}

.pres-text img {
  max-width: 100%;
}

.pres-text .booth-images-3col {
  width: 100%;
  display: grid;
  margin-right: 10px;
  column-gap: 10px;
  grid-template-columns: auto auto auto;
}

.slides {
  width: 100%;
  overflow: hidden;
}

.pnbuts {
  text-align: center;
  padding: 20px;
}
.pnbuts button span {
  font-size: 125%;
  line-height: 1.15;
}
.slideThumb {
  padding: 10px 20px;
  cursor: pointer;
}

.use-spring-carousel-thumbs-wrapper {
  overflow-x: initial !important;
  min-height: 100px; 
}

.slideThumb.active img {
  transform: scale(1.2, 1.2);
  transition: all .3s;
  border: 2px solid rgba(1, 1, 1, 0.2)
}


