.App {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.stage1,
.stage2,
.stage3 {
  font-family: Arial, Helvetica, sans-serif;

}