.ChannelStrip {
  height: 75vh;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 10px 10px;
}

:root {
  --aux-main-text: #fff !important;
  --aux-main-tinyfontsize: 12px !important;
  --aux-active-base: darkorange !important;
  --aux-scale-fontweight: bold !important;
  margin: 0 auto;
  width: 100%;
}

.aux-meter > .aux-bar > .aux-mask {
  opacity: 1;
}

.FullHeight {
  padding: 10px 5px;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  grid-template-rows: 1fr; 
  grid-column-gap: 0px;
  grid-row-gap: 0px; 
  font-style: bold;
  border-radius: 10px;
}
.Label {
  text-align: center;
}
